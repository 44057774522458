import React, {useEffect, useState} from 'react';
import {Route, Switch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import './App.css';
import './../node_modules/normalize.css/normalize.css';
import Loader from "./components/Loader/Loader";

import {getAuthDataThunk, onRedirectAuntifiation} from "./store/reducers/AuthReducer";
import MainServiceContainer from "./pages/MainService/MainServiceContainer";
import Alert from './components/Alert/Alert';
import Ordering from "./pages/Ordering";
import InfoPages from "./pages/InfoPages/InfoPages";
import LayoutCabinet from "./pages/Cabinet/LayoutCabinet";
import {LangProvider} from "./context/langProvider";
import {Redirect} from "react-router";
import {SignIn, SignUp} from "./pages/SignPlatform";
import HelpArticleModal from "./components/Modals/HelpArticle/HelpArticleModal";
import WarningModalContainer from "./components/Modals/WarningModal/WarningModalContainer";


function redirectToProject(params, type = 'short') {
    switch (type) {
        case "short": {
            return `/project/${params.code}/1`
        }
        case "long": {
            let {code, client, firm, manager} = params
            return `/project/${code}/1/${client}/${firm}/${manager}`
        }
        default: {
            return '/account'
        }
    }
}

export default function AppContainer() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const loader = useSelector(state => state.commons.loader);
    const alert = useSelector(state => state.commons.alert);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const value = query.get('auth_token');
        async function auth() {
            await dispatch(getAuthDataThunk())
        }


        if (!window.location.pathname.includes('/registration') || value) {
            auth().then(res => {
                dispatch(onRedirectAuntifiation());
                setIsLoading(false);
            }).catch(err => console.log('403', err))
        }else{
            setIsLoading(false);
        }
        console.clear()
    }, []);
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'updateForTabs') {
                // Получите новые данные и выполните действия с ними
                const updateData = JSON.parse(event.newValue);
                console.log(updateData.message); // Здесь может быть ваша логика обновления
                localStorage.removeItem('updateForTabs')
                window.location.reload()
            }
        };

        // Добавьте слушателя событий
        window.addEventListener('storage', handleStorageChange);

        // Очистите слушателя событий при размонтировании компонента
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);
    useEffect(()=>{
        const handleWheel = (event) => {
            if (document.activeElement.type === 'number') {
                document.activeElement.blur();
            }
        };

        const handleKeyDown = (event) => {
            if (document.activeElement.type === 'number' && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
                event.preventDefault();
            }
        };

        window.addEventListener('wheel', handleWheel);
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('wheel', handleWheel);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [])
    if (isLoading) return <Loader/>

    return <LangProvider>
        <App isLogin={auth.isLogin} loader={loader} alert={alert}/>
    </LangProvider>
}



class App extends React.Component {
    render() {
        return <div className={'main_wrapper'}>
            {this.props.isLogin ?
                <div className={'main_wrapper'}>
                    <Switch>

                        <Route exact path={'/project/:code'}
                               render={({match}) => {
                                   let url = redirectToProject(match.params)
                                   return <Redirect to={url}/>
                               }}
                        />
                        <Route exact path={'/project/:code/:version'}
                               render={(match) => <MainServiceContainer uri={match}/>}
                        />
                        <Route exact path={'/project/:code//:client/:firm/:manager'}
                               render={({match}) => {
                                   let url = redirectToProject(match.params, 'long')
                                   return <Redirect to={url}/>
                               }}
                        />
                        <Route exact path={'/project/:code/:client/:firm/:manager'}
                               render={({match}) => {
                                   let url = redirectToProject(match.params, 'long')
                                   return <Redirect to={url}/>
                               }}
                        />
                        <Route exact path={'/project/:code/:version/:client/:firm/:manager'}
                               render={(match) => <MainServiceContainer uri={match}/>}
                        />


                        <Route exact path={'/ordering'}>
                            <Ordering/>
                        </Route>
                        <Route exact path={'/pages/:page'}
                               render={(match) => <InfoPages uri={match}/>}
                        />
                        <Route exact path={'/account'}>
                            <LayoutCabinet/>
                        </Route>
                        <Route path={'/'}>
                            <Redirect to="/account"/>
                            {/*<LayoutCabinet/>*/}
                        </Route>
                    </Switch>
                </div>
                :
                <div className={'main_wrapper'}>
                    <Switch>

                        <Route exact path={'/project/:code'}
                               render={({match}) => {
                                   let url = redirectToProject(match.params)
                                   return <Redirect to={url}/>
                               }}
                        />
                        <Route exact path={'/project/:code/:version'}
                               render={(match) => <MainServiceContainer uri={match}/>}
                        />
                        <Route exact path={'/project/:code//:client/:firm/:manager'}
                               render={({match}) => {
                                   let url = redirectToProject(match.params, 'long')
                                   return <Redirect to={url}/>
                               }}
                        />
                        <Route exact path={'/project/:code/:client/:firm/:manager'}
                               render={({match}) => {
                                   let url = redirectToProject(match.params, 'long')
                                   return <Redirect to={url}/>
                               }}
                        />
                        <Route exact path={'/project/:code/:version/:client/:firm/:manager'}
                               render={(match) => <MainServiceContainer uri={match}/>}
                        />
                        <Route exact path={'/pages/:page'}
                               render={(match) => <InfoPages uri={match}/>}
                        />

                        <Route exact path={'/login/:firm'}
                               render={(route) => {
                                   let {params} = route?.match;
                                   let {firm = null} = params;
                                   return <SignIn firm_code={firm}/>
                               }}>
                        </Route>
                        <Route exact path={'/login/'}>
                            <SignIn firm_code={window?.history?.state?.state?.firm_code}/>
                        </Route>
                        {/*<Route exact path={'/registration/:firm'} render={(match) => <ReqisterContainer url={match}/>}></Route>*/}
                        <Route exact path={'/registration/:firm'} render={(route) => {
                            let {params} = route?.match;
                            let {firm=null} = params;
                            // if(!firm) {
                            //     return  <Redirect to={'/login'}/>
                            // }
                            return <SignUp firm_code={firm}/>
                        }}>

                        </Route>
                        <Route path={'/account'}>
                            <Redirect to="/login"/>
                        </Route>
                        <Route path={'/'} render={() => {
                            return <Redirect to="/login"/>
                        }}>
                        </Route>
                    </Switch>
                </div>
            }
            {this.props.loader.active && <Loader message={this.props.loader.message}/>}
            {this.props.alert.active && <Alert message={this.props.alert.message}/>}
            <HelpArticleModal/>
            <WarningModalContainer/>
        </div>;
    };
}

let mapStateToProps = (state) => {
    return {
        auth: state.auth,
        loader: state.commons.loader,
        alert: state.commons.alert,
    }
};

let mapDispatchToProps = (dispatch) => {
    return {
        getAuthData: (firm) => dispatch(getAuthDataThunk(firm)),
        isCheckRedirectAuthenticator: () => dispatch(onRedirectAuntifiation()),
    }
};

// export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);

